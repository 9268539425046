import { createRouter, createWebHistory } from 'vue-router';
import { hasDictionary, loadDictionary } from './i18n';

import { Permission } from "@/core/permission";
import SessionTracker from "@/core/session";

export function setupRouter( store, i18n, version )
{
    const $_permission = new Permission({ store });

    if( ['production','test'].includes( import.meta.env.MODE ) )
    {
        SessionTracker.init( { rootUrl: import.meta.env.VITE_APP_TRACKER_API_URL, paths: { record: '/recording/checkpoint', tracker: '/track', feedback: '/feedback' } , timeout: { read: 30000, write: 5000 } } );
    }

    const routes =
    [
        {
            path      : '/',
            name      : 'Root',
            component : () => import('@/templates/layouts/Layout.vue'),
            redirect  : '/dashboard',
            children  :
            [
                //OVERVIEW
                {
                    path        : '/dashboard',
                    name        : 'Dashboard',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'default', title: 'Home', breadcrumb: 'Home', icon: 'isax isax-element-45' },
                    redirect    : '/dashboard',
                    children    :
                    [
                        {
                            path        : '/dashboard',
                            name        : 'DashboardOverview',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/dashboard/overview/detail/Detail.vue')
                        }
                    ]
                },

                //MY PROFILE
                {
                    path        : '/my-profile',
                    name        : 'MyProfile',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'recruiter' ], section: 'default', title: 'My account', breadcrumb: 'My account', icon: 'isax isax-tag-user5' },
                    beforeEnter : ( to, from, next ) => { next( '/accounts/'+ store.getters['auth/user/detail']?.currentAccount.id ); },
                },

                //COMPANY PROFILE
                {
                    path        : '/company-profile',
                    name        : 'CompanyProfile',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'recruiter' ], section: 'default', title: 'Company profile', breadcrumb: 'Company profile', icon: 'isax isax-building5' },
                    beforeEnter : ( to, from, next ) => { next( '/agencies/'+ store.getters['auth/user/detail']?.currentAccount.organization.id ); },
                },

                //INBOX
                {
                    path        : '/messages',
                    name        : 'Mailbox',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'default', name: 'Mailbox', title: 'Mailbox', key: 'unread_conversations', breadcrumb: 'Mailbox', icon: 'isax isax-sms5', disableLayoutShift: true },
                    redirect    : '/messages',
                    children    :
                    [
                        {
                            path        : '/messages',
                            name        : 'MessagesList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/messages/mailbox/list/List.vue'),
                            children    :
                            [
                                {
                                    path        : '/messages/:conversationID',
                                    name        : 'MessageDetail',
                                    meta        : { requiresAuth: true },
                                    props       : ( route ) => { return { ...route.params, conversationID: route.params.conversationID } },
                                    component   : () => import('@/templates/views/messages/mailbox/list/blocks/InboxDetail.vue')
                                }
                            ]
                        }
                    ]
                },

                //LIVECHAT
                {
                    path        : '/livechat',
                    name        : 'Livechat',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin' ], section: 'default', name: 'Livechat', title: 'Livechat', key: 'unread_livechat_conversations', breadcrumb: 'Livechat', icon: 'isax isax-microphone-25', disableLayoutShift: true },
                    redirect    : '/livechat',
                    children    :
                    [
                        {
                            path        : '/livechat',
                            name        : 'LivechatList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/livechat/mailbox/list/List.vue'),
                            children    :
                            [
                                {
                                    path        : '/livechat/:threadID',
                                    name        : 'LivechatDetail',
                                    meta        : { requiresAuth: true },
                                    props       : ( route ) => { return { ...route.params, threadID: route.params.threadID } },
                                    component   : () => import('@/templates/views/livechat/mailbox/list/blocks/InboxDetail.vue')
                                }
                            ]
                        }
                    ]
                },
                
                /*
                //TASKS
                {
                    path        : '/tasks',
                    name        : 'Tasks',
                    meta        : { requiresAuth: true, navigation: true, section: 'main', title: 'Tasks', breadcrumb: 'Tasks', icon: 'isax isax-calendar5' },
                    redirect    : '/tasks/dashboard',
                    children    :
                    [
                        {
                            path        : '/tasks/dashboard',
                            name        : 'TasksOverview',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/tasks/overview/detail/Detail.vue')
                        }
                    ]
                },
               */

                //HIDDEN => EMPLOYMENT => ACCOUNTS
                {
                    path        : '/accounts',
                    name        : 'Accounts',
                    meta        : { requiresAuth: true, navigation: false, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'Accounts', breadcrumb: 'Accounts', icon: 'fa-users-tie' },
                    redirect    : '/accounts',
                    children    :
                    [
                        {
                            path        : '/accounts',
                            name        : 'AccountsList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/accounts/list/List.vue')
                        },
                        {
                            path        : '/accounts/:accountID',
                            name        : 'AccountDetail',
                            meta        : { requiresAuth: true },
                            props       : ( route ) => { return { ...route.params, accountID: route.params.accountID } },
                            component   : () => import('@/templates/views/recruitment/accounts/detail/Detail.vue'),
                        }
                    ]
                },

                //HIDDEN => EMPLOYMENT => PEOPLE ( CANDIDATES, CONTRACTORS )
                {
                    path        : '/people',
                    name        : 'People',
                    meta        : { requiresAuth: true, navigation: false, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'People', breadcrumb: 'People', icon: 'fa-users' },
                    redirect    : '/people',
                    children    :
                    [
                        {
                            path        : '/people',
                            name        : 'PeopleList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/people/list/List.vue')
                        },
                        {
                            path        : '/people/:personID',
                            name        : 'PersonDetail',
                            meta        : { requiresAuth: true },
                            props       : ( route ) => { return { ...route.params, personID: route.params.personID } },
                            component   : () => import('@/templates/views/recruitment/people/detail/Detail.vue'),
                        }
                    ]
                },

                //EMPLOYMENT => JOBS
                {
                    path        : '/jobs',
                    name        : 'Jobs',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'Job requisitions', breadcrumb: 'Job requisitions', icon: 'isax isax-briefcase5' },
                    redirect    : '/jobs',
                    children    :
                    [
                        {
                            path        : '/jobs',
                            name        : 'JobsList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/jobs/list/List.vue')
                        },
                        {
                            path        : '/jobs/:jobID',
                            name        : 'JobDetail',
                            meta        : { requiresAuth: true },
                            props       : ( route ) => { return { ...route.params, jobID: route.params.jobID } },
                            component   : () => import('@/templates/views/recruitment/jobs/detail/Detail.vue'),
                            children    :
                            [
                                {
                                    path        : '/jobs/:jobID/:conversationID',
                                    name        : 'JobMessageDetail',
                                    meta        : { requiresAuth: true, disableLayoutShift: true },
                                    props       : ( route ) => { return { ...route.params, jobID: route.params.jobID, conversationID: route.params.conversationID } },
                                    component   : () => import('@/templates/views/messages/mailbox/detail/Detail.vue')
                                }
                            ]
                        }
                    ]
                },

                 //EMPLOYMENT => INVITATIONS
                 {
                    path        : '/invitations',
                    name        : 'Invitations',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'Invitations', breadcrumb: 'Invitations', icon: 'isax isax-archive-tick5' },
                    redirect    : '/invitations',
                    children    :
                    [
                        {
                            path        : '/invitations',
                            name        : 'InvitationsList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/invitations/list/List.vue')
                        }
                    ]
                },

                //EMPLOYMENT => APPLICATIONS
                {
                    path        : '/applications',
                    name        : 'Applications',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'Applications', breadcrumb: 'Applications', icon: 'isax isax-personalcard5' },
                    redirect    : '/applications',
                    children    :
                    [
                        {
                            path        : '/applications',
                            name        : 'ApplicationsList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/applications/list/List.vue'),
                        },
                        {
                            path        : '/applications/:applicationID',
                            name        : 'ApplicationDetail',
                            meta        : { requiresAuth: true },
                            props       : (route) => { return { ...route.params, applicationID: route.params.applicationID } },
                            component   : () => import('@/templates/views/recruitment/applications/detail/Detail.vue'),
                            children    :
                            [
                                {
                                    path        : '/applications/:applicationID/:conversationID',
                                    name        : 'ApplicationMessageDetail',
                                    meta        : { requiresAuth: true, disableLayoutShift: true },
                                    props       : ( route ) => { return { ...route.params, applicationID: route.params.applicationID, conversationID: route.params.conversationID } },
                                    component   : () => import('@/templates/views/messages/mailbox/detail/Detail.vue')
                                }
                            ]
                        }
                    ]
                },

                //EMPLOYMENT => PLACEMENTS
                {
                    path        : '/placements',
                    name        : 'Placements',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'Placements', breadcrumb: 'Placements', icon: 'isax isax-cd5' },
                    redirect    : '/placements',
                    children    :
                    [
                        {
                            path        : '/placements',
                            name        : 'PlacementsList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/placements/list/List.vue')
                        },
                        {
                            path        : '/placements/:placementID',
                            name        : 'PlacementDetail',
                            meta        : { requiresAuth: true },
                            props       : ( route ) => { return { ...route.params, placementID: route.params.placementID } },
                            component   : () => import('@/templates/views/recruitment/placements/detail/Detail.vue'),
                            children    :
                            [
                                {
                                    path        : '/placements/:placementID/:conversationID',
                                    name        : 'PlacementMessageDetail',
                                    meta        : { requiresAuth: true, disableLayoutShift: true },
                                    props       : ( route ) => { return { ...route.params, placementID: route.params.placementID, conversationID: route.params.conversationID } },
                                    component   : () => import('@/templates/views/messages/mailbox/detail/Detail.vue')
                                }
                            ]
                        }
                    ]
                },

                //EMPLOYMENT => CONTRACTS
                {
                    path        : '/contracts',
                    name        : 'Contractors',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'Contractors', breadcrumb: 'Contractors', icon: 'isax isax-document-text5' },
                    redirect    : '/contracts',
                    children    :
                    [
                        {
                            path        : '/contracts',
                            name        : 'ContractsList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/contracts/list/List.vue')
                        },
                        {
                            path        : '/contracts/:contractID',
                            name        : 'ContractDetail',
                            meta        : { requiresAuth: true },
                            props       : ( route ) => { return { ...route.params, contractID: route.params.contractID } },
                            component   : () => import('@/templates/views/recruitment/contracts/detail/Detail.vue'),
                            children    :
                            [
                                {
                                    path        : '/contracts/:contractID/:conversationID',
                                    name        : 'ContractMessageDetail',
                                    meta        : { requiresAuth: true, disableLayoutShift: true },
                                    props       : ( route ) => { return { ...route.params, contractID: route.params.contractID, conversationID: route.params.conversationID } },
                                    component   : () => import('@/templates/views/messages/mailbox/detail/Detail.vue')
                                }
                            ]
                        }
                    ]
                },

                //EMPLOYMENT => AGENCIES
                {
                    path        : '/agencies',
                    name        : 'Agencies',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'recruitment', title: 'Agencies', breadcrumb: 'Agencies', icon: 'isax isax-buliding5' },
                    redirect    : '/agencies',
                    children    :
                    [
                        {
                            path        : '/agencies',
                            name        : 'AgenciesList',
                            meta        : { requiresAuth: true, permissions: [ 'admin', 'employer' ] },
                            component   : () => import('@/templates/views/recruitment/agencies/list/List.vue')
                        },
                        {
                            path        : '/agencies/:agencyID',
                            name        : 'AgencyDetail',
                            meta        : { requiresAuth: true, permissions: [ 'admin', 'employer', 'recruiter' ] },
                            props       : (route) => { return { ...route.params, agencyID: route.params.agencyID } },
                            component   : () => import('@/templates/views/recruitment/agencies/detail/Detail.vue'),
                            children    :
                            [
                                {
                                    path        : '/agencies/:agencyID/:conversationID',
                                    name        : 'AgencyMessageDetail',
                                    meta        : { requiresAuth: true, disableLayoutShift: true },
                                    props       : ( route ) => { return { ...route.params, agencyID: route.params.agencyID, conversationID: route.params.conversationID } },
                                    component   : () => import('@/templates/views/messages/mailbox/list/blocks/InboxDetail.vue')
                                }
                            ]
                        }
                    ]
                },

                 //EMPLOYMENT => PROGRAMMES
                 {
                    path        : '/programmes',
                    name        : 'Programs',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin' ], section: 'recruitment', title: 'Programs', breadcrumb: 'Programs', icon: 'isax isax-dcube5' },
                    redirect    : '/programmes',
                    children    :
                    [
                        {
                            path        : '/programmes',
                            name        : 'ProgrammesList',
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/recruitment/programmes/list/List.vue'),
                        },
                        {
                            path        : '/programmes/:programmeID',
                            name        : 'ProgrammeDetail',
                            meta        : { requiresAuth: true, queryKeys: ['programmeLocationID'] },
                            props       : ( route ) => { return { ...route.params, programmeID: route.params.programmeID } },
                            component   : () => import('@/templates/views/recruitment/programmes/detail/Detail.vue'),
                        }
                    ]
                },

                //TOOLS => ANALYTICS
                {
                    path        : '/analytics',
                    name        : 'Analytics',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer' ], section: 'tool', title: 'Reporting & analytics', breadcrumb: 'Reporting & analytics', icon: 'isax isax-chart-35' },
                    redirect    : '/analytics/administrator',
                    children    :
                    [
                        {
                            path        : 'administrator',
                            name        : 'administratorAnalyticsOverview',
                            props       : ( route ) => { return { role: 'administrator', version: 1  } },
                            meta        : { requiresAuth: true },
                            component   : () => import('@/templates/views/dashboard/analytics/detail/Detail.vue')
                        }
                    ]
                },

                /*
                //TOOLS => JOB TRACKER
                {
                    path        : '/job-tracker',
                    name        : 'JobTracker',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin' ], section: 'tool', title: 'Job market tracker', breadcrumb: 'Job market tracker', icon: 'isax isax-presention-chart5' },
                    component   : () => import('@/templates/views/dashboard/jobtracker/detail/Detail.vue')
                },

                //TOOLS => TALENT INTELLIGENCE
                {
                    path        : '/insights',
                    name        : 'InsightsMap',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin' ], section: 'tool', title: 'Talent intelligence', breadcrumb: 'Talent intelligence', icon: 'isax isax-global-search5' },
                    component   : () => import('@/templates/views/dashboard/insights/map/Map.vue')
                },
                */
                //SETTINGS => PROFILE
                {
                    path        : '/profile',
                    name        : 'Profile',
                    meta        : { requiresAuth: true, navigation: false },
                    redirect    : '/profile',
                    children    :
                    [
                        {
                            path        : '/profile',
                            name        : 'ProfileDetail',
                            meta        : { requiresAuth: true, disableLayoutShift: true },
                            component   : () => import('@/templates/views/profile/detail/Detail.vue'),
                            children    :
                            [
                                {
                                    path        : '/profile/company/:sectionID',
                                    name        : 'ProfileCompanyDetail',
                                    meta        : { requiresAuth: true },
                                    props       : ( route ) => { return { ...route.params, sectionID: route.params.sectionID } },
                                    component   : () => import('@/templates/views/profile/detail/blocks/company/list/blocks/Detail.vue')
                                },
                                {
                                    path        : ':sectionID',
                                    name        : 'ProfileAccountDetail',
                                    meta        : { requiresAuth: true },
                                    props       : ( route ) => { return { ...route.params, sectionID: route.params.sectionID } },
                                    component   : () => import('@/templates/views/profile/detail/blocks/profile/list/blocks/Detail.vue')
                                }
                            ]
                        }
                    ]
                },

                /*
                {
                    path        : '/insights-calc',
                    name        : 'InsightsCalc',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'tool', title: 'Insights calc', breadcrumb: 'Insights calc', icon: 'isax isax-calculator5' },
                    component   : () => import('@/templates/views/dashboard/insights/detail/Detail.vue')
                },
                {
                    path        : '/insights-ai-job',
                    name        : 'InsightsAIJob',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'tool', title: 'RAMP Copilot', breadcrumb: 'AI job', icon: 'isax isax-magicpen5' },
                    component   : () => import('@/templates/views/dashboard/insights/ai/Job.vue')
                },
                {
                    path        : '/insights-ai-chat',
                    name        : 'InsightsAIChat',
                    meta        : { requiresAuth: true, navigation: true, permissions: [ 'admin', 'employer', 'recruiter' ], section: 'tool', title: 'RAMP Copilot Chat', breadcrumb: 'AI Chat', icon: 'isax isax-magicpen5' },
                    component   : () => import('@/templates/views/dashboard/insights/ai/Chat.vue')
                },
                */
            ]
        },

        {
            path        : '/stripe-session',
            name        : 'StripeSession',
            meta        : { requiresAuth: true, navigation: false },
            component   : () => import('@/templates/views/system/StripeSession.vue'),
            beforeEnter : async ( to, from, next ) =>
            {
                if( !to.query.hasOwnProperty('session_id') )
                {
                    next({ path: '/missing-stripe-session-id', query: { statusCode: 400 } });
                }
                else
                {
                    next();
                }
            }
        },
        {
            path        : '/login',
            name        : 'Login',
            meta        : { requiresAuth : false },
            beforeEnter : async ( to, from ) => { await store.dispatch('auth/user/login'); }
        },
        {
            path        : '/logout',
            name        : 'Logout',
            meta        : { requiresAuth : false },
            //beforeEnter : async ( to, from ) => { await store.dispatch('auth/user/logout'); },
            component   : () => import('@/templates/views/system/Logout.vue')
        },
        {
            path        : '/select-account',
            name        : 'SelectAccount',
            meta        : { requiresAuth : true },
            component   : () => import('@/templates/views/system/SelectAccount.vue')
        },
        {
            path        : '/access-denied',
            name        : 'AccessDenied',
            meta        : { requiresAuth : false },
            props       : ( route ) => { return { statusCode: 403 } },
            component   : () => import('@/templates/views/system/ErrorPage.vue')
        },
        {
            path        : '/:pathMatch(.*)*',
            name        : '404',
            meta        : { requiresAuth: false },
            props       : ( route ) => { return { statusCode: route?.query?.statusCode || 404 } },
            component   : () => import('@/templates/views/system/ErrorPage.vue')
        }
    ]

    const router = createRouter({ history: createWebHistory(), routes });

    router.beforeEach( async ( to, from ) =>
    {
        if( to.query.authToken )
        {
            try
            {
                await store.dispatch( 'auth/user/loginWithToken', { token: to.query.authToken });
            }
            catch( e )
            {
                const { authToken, ...newQuery } = to.query;

                router.replace({ path: to.path, query: newQuery });
            }
        }

        if( !store.getters['auth/user/detail'] )
        {
            await store.dispatch( 'auth/user/get', { requiresAuth: to.meta.requiresAuth });
        }

        if( !hasDictionary( i18n, 'en' ) )
        {
            await loadDictionary( i18n, 'en' );
        }

        if( store.getters['auth/user/detail'] && !store.getters['auth/user/detail'].emailVerified )
        {
            window.location.href = import.meta.env.VITE_APP_AUTH_URL + 'registration/verification?email=' + store.getters['auth/user/detail'].email;
        }

        if( to.meta.requiresAuth && !store.getters['auth/user/detail']?.currentAccount && to.path !== '/select-account' )
        {
            sessionStorage.setItem( "redirectAfterAccountSelected", window.location.href );

            return { path: '/select-account' };
        }
        else if( to.meta.requiresAuth && store.getters['auth/user/detail']?.currentAccount && to.path === '/select-account' )
        {
            return { path: '/' };
        }

        if( store.getters['auth/user/isLoggedIn'] && to.meta.requiresAuth && to.meta.permissions && !$_permission.has( to.meta.permissions ) )
        {
            return { path: '/access-denied' };
        }

        if( store.getters['auth/user/detail']?.currentAccount )
        {
            const { currentAccount, id } = store.getters['auth/user/detail'];

            if( ['production','test'].includes( import.meta.env.MODE ) && !( to && from && to.name === from.name && ( to.name.includes('List') || to.name.includes('Detail') ) && ( to.query.cursor === from.query.cursor || to.cursor !== from.query.cursor ) ) )
            {
                SessionTracker.page( to.name + ( to.query.currentTab ? '_' + to.query.currentTab : '' ),
                {
                    url      : ( new URL( to.fullPath, location.href ) ).toString(),
                    referrer : ( new URL( from.fullPath, location.href ) ).toString(),
                    user     : { id: id, accountID: currentAccount.id, organizationID: currentAccount.organization.id }
                });
            }
        }
    });

    router.onError(( error, to ) =>
    {
        if( error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed") )
        {
            version.check( true );
        }
    })

    return router;
}